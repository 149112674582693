/* Style for the NoStreaming component */

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden; /* Prevent vertical scrollbar */
  }
  
  .no-streaming-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Ensure it takes up the entire viewport height */
    background: linear-gradient(135deg, #f2f2f2, #e6e6e6);
    text-align: center;
  }
  
  .message-box {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 90%; /* To ensure it doesn't overflow on small screens */
  }
  
  .message-box h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .message-box p {
    font-size: 1.2rem;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .message-box h1 {
      font-size: 2rem;
    }
  
    .message-box p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .message-box h1 {
      font-size: 1.5rem;
    }
  
    .message-box p {
      font-size: 0.9rem;
    }
  }
  