.scrollable-team-container::-webkit-scrollbar {
    width: 20px;
}

.team-card {
    /* flex: 0 0 auto; */
    margin-right: 20px;
    /* transition: box-shadow 0.3s ease, border 0.3s ease; */
}

.team-card.active {
    box-shadow: 0px 0px 15px 5px white;
    border: 2px solid white;
}

.team-card.inactive {
    transition: box-shadow 0.3s ease, border 0.3s ease;
    border: 1px solid #898989;
}

.img-team {
    height: 60%;
    width: 60%;
    display: block;
    margin: 0 auto;
    max-width: 100px; /* Ensures image is constrained on smaller screens */
    max-height: 100px;
}

.scrollable-team-container {
    overflow-y: auto;
    height: 100vh; /* Full viewport height */
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
}
 
 
