
@media (min-width: 768px) {
    .top-section {
        display: flex;
        gap: 16px; /* Adjust gap as needed */
    }
    
    .top-section > div {
        flex: 1;
        display: flex;
    }
    
    .jumbotron-custom {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }
}

/* Image size */
.img-user {
    height: 100%;
    width: 100%;
    margin: 0 auto;
}

/* For larger screens */
@media (min-width: 1024px) {
    .img-user {
        width: 150px;
        height: 150px;
    }
}

/* White text */
.color-white {
    color: white !important;
}

.center-text {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Vertically center the content */
    height: 150px; /* Same as the image height */
}

/* Jumbotron styling for smaller screens */
@media (max-width: 768px) {
    .jumbotron {
        padding: 20px;
        border-radius: 10px;
    }
}

@media (max-width: 576px) {
    .img-user {
        width: 120px;
        height: 120px;
    }

    h2 {
        font-size: 250%;
    }
}

/* Flexbox adjustment for row */
@media (max-width: 576px) {
    .row {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .img-user {
        width: 120px;
        height: 120px;
    }
}

@media (max-width: 1024px) {
    .row {
        text-align: center;
    }

    .img-user {
        width: 120px;
        height: 120px;
    }
}

@media (max-width: 1268px) {
    .img-user {
        width: 120px;
        height: 120px;
    }
}

@media (width: 1024px) {
    h2 {
        font-size: 400%;
    }

    h4 {
        font-size: 280%;
    }

    h3 {
        font-size: 300%;
    }
}

@media (width: 768px) {
    h4 {
        font-size: 160%;
    }
}